import { mapState } from 'vuex';
const mapText = {
  service_aim: ['服务理念', 'Service Aim', '服務理念'],
  cwq_user: ['常卫清®用户', 'ColoClear® User', '常衛清®用戶'],
  uu_user: ['幽幽管®用户', 'UU Tube® User', '幽幽管®用戶'],
  pp_user: ['噗噗管®用户', 'Pupu Tube® User', '噗噗管®用戶'],
  our_target: ['我们希望将产品做成有自主知识产权的硬核技术，成为有品牌价值的快速医疗消费品，帮助更多用户轻松居家完成癌症早筛。', 'We focus on user experience and focus on home scenarios to help more people detect disease risks in time and effectively prevent them, and improve their awareness of health management.', '我們希望將產品做成有自主知識產權的硬核科技，成為有品牌價值的快速醫療消費品，幫助更多用戶輕鬆居家完成癌症早篩。']
};
const aLinks = [{
  id: 'our_target',
  name: mapText.service_aim
}, {
  id: 'user_cwq',
  name: mapText.cwq_user
}, {
  id: 'user_uu',
  name: mapText.uu_user
}, {
  id: 'user_pp',
  name: mapText.pp_user
}];
export default {
  name: '',
  components: {},
  props: {},

  data() {
    return {
      title: this.$api.api_title,
      userStoryList: [],
      userHeadStoryList: [],
      showLpayer: false,
      news_list: {},
      aLinks,
      currentIndex: 0,
      mapText
    };
  },

  computed: { ...mapState({
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {},

  created() {
    this.getData();
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
    this.$nextTick(() => {
      if (this.device == 'pc') {
        this.changeClass(-1, this.$refs.user_story_cwq, 'animate__fadeInRight', 0);
      }
    });
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },

  methods: {
    // closePlayer(){
    // 	this.showLpayer = false
    // 	this.playLink = ''
    // },
    // playVideo(event){
    // 	this.showLpayer = true
    // 	var el = event.target
    // 	this.playLink = el.getAttribute('data-link')		
    // },
    getData() {
      this.$http.post(this.$api.story_userStoryList, {
        nhLanguage: this.$store.state.language
      }).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.userStoryList = res.data;
          }
        }
      });
      this.$http.post(this.$api.storyHeader_userStoryHeaderList, {
        nhLanguage: this.$store.state.language
      }).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.userHeadStoryList = res.data;
          }
        }
      }); //首页用户故事

      this.$http.get(this.$api.cmsHomeNewsList).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.news_list = res.data[0];
          }
        }
      });
    },

    scrollIntoView(id, index) {
      this.currentIndex = index;
      const el = document.getElementById(id);
      if (el) el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      });
    },

    handleScroll() {
      // 价值观
      const user_story_title = this.$refs.user_story_title.getBoundingClientRect().top; // if (this.device == 'pc') {
      // 	console.log('🚀 ~ user_story_title:', user_story_title)
      // 	this.changeClass(user_story_title, this.$refs.user_story_title, 'animate__fadeInLeft', 620)
      // 	this.changeClass(user_story_title, this.$refs.user_story_cwq, 'animate__fadeInRight', 525)
      // }

      this.changeClass(user_story_title, this.$refs.user_story_uu, 'animate__fadeInLeft', -650);
      this.changeClass(user_story_title, this.$refs.user_story_pp, 'animate__fadeInRight', -1200);
    },

    changeClass(distance, target, animate, top) {
      if (distance > top) return;
      target.classList.add(animate);
      target.classList.remove('display-none');
    }

  }
};